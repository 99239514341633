export const de = {
  seoDefaults: {
    albis: {
      title: "ALBIS - Easy, Smart, passionate.",
      brandPrefix: "ALBIS - ",
      description:
        "Distribution von Kunststoffen. Versierter Experte und unabh. Berater rund um das Thema Kunststoff. Eines der breitesten Portfolios der Welt.",
    },
    mocom: {
      title: "MOCOM - Compounding excellence.",
      brandPrefix: "MOCOM - ",
      description: "Compoundierung von Kunststoffen.",
    },
    krahnCeramics: {
      title:
        "KRAHN CERAMICS Projektpartner für die Verarbeitung von Keramik- und Metallpulvern",
      brandPrefix: "KRAHN CERAMICS - ",
      description:
        "Wir sind Ihr innovativer Projektpartner für die Verarbeitung von Keramik- und Metallpulvern über alle Prozessstufen hinweg",
    },
    ottoKrahnGroup: {
      title: "Otto Krahn Group – Start-up für Fortgeschrittene.",

      brandPrefix: "Otto Krahn Group - ",
      description:
        "Mit 111 Jahren geben wir uns eine neue Struktur: ALBIS, KRAHN, MOCOM, WIPAG vereint in der neuen Holding. Ihre globalen Experten für Kunststoffe und Chemie.",
    },
    htf: {
      title: "KRAHN Wärmeträgerflüssigkeiten – offizieller Partner von Eastman",

      brandPrefix: "KRAHN Wärmeträgerflüssigkeiten - ",
      description:
        "Auswahl der richtigen Wärmeträgerflüssigkeit aus dem Therminol®-/Marlotherm™-Portfolio. Umfassende Beratung zu den Themen Erhitzer, Pumpen und Ablufttechnologien.",
    },
    wipag: {
      title: "WIPAG - We close the loop.",

      brandPrefix: "WIPAG - ",
      description:
        "Spezialist für die Herstellung von Compounds durch Kunststoffaufbereitung sowie die Umsetzung individueller Kreislaufwirtschaftskonzepte.",
    },
    plgrd: {
      title: "Playground",
      brandPrefix: "Playground - ",
      description: "Playground zum Spielen",
    },
    krahnChemie: {
      title: "Krahn Chemie",
      brandPrefix: "Krahn Chemie - ",
      description: "Krahn Chemie",
    },
    okNewBusiness: {
      title: "Otto Krahn New Business",
      brandPrefix: "Otto Krahn New Business",
      description: "Otto Krahn New Business",
    },
  },
  header: {
    contact: "Kontakt",
    outline: "Übersicht",
  },
  search: {
    search: "Suche",
    label: "Suchen",
    lastSearches: "Ihre letzten Suchen",
    directEnter: "Direkteinstieg zu",
    files: "Dateien",
    stories: "Beiträge",
    pages: "Seiten",
    products: "Produkte",
    press: "Pressemitteilung",
    industries: "Industrien",
    results: "Ergebnisse für",
    more: "Mehr anzeigen",
    noResults: "Leider gibt es keine Ergebnisse für",
    adjustSearch:
      "Bitte passen Sie Ihren Suchbegriff an und versuchen Sie es nochmal.",
    download: "Download",
    short: "Ihr Suchbegriff ist zu kurz.",
    entrances: {
      albis: [
        {
          link: "/de/products/product-search/",
          label: "Datenblätter",
        },
        {
          link: "/de/services/quality-management",
          label: "Zertifikate",
        },
        {
          link: "/de/products/brochures",
          label: "Sicherheitsdatenblätter",
        },
      ],
      wipag: [
        {
          link: "/de/downloads",
          label: "Downloads & Zertifikate",
        },
      ],
      mocom: [
        {
          link: "/de/products/product-search#/",
          label: "Datenblätter",
        },
        {
          link: "/de/downloads",
          label: "Sicherheitsdatenblätter & Zertifikate",
        },
      ],
      ottoKrahnGroup: [{ link: "/de/brands", label: "Marken" }],
      krahnChemie: [{ link: "/de/markets", label: "Märkte" }],
    },
  },
  products: {
    viewProduct: "Produkt ansehen",
    back: "Zurück zur Übersicht",
  },
  newsletter: {
    mandatory: "* Pflichtfeld",
  },
  contact: {
    phone: "Tel:",
    fax: "Fax:",
    email: "E-Mail:",
    writeEMail: "E-Mail schreiben",
    form: {
      button: "Abschicken",
      send: "Senden",
      placeholder: {
        email: "E-Mail",
        name: "Name",
        company: "Firma (optional)",
        companyReq: "Firma",
        street: "Straße",
        apartmentNumber: "Hausnummer",
        plz: "PLZ",
        city: "Stadt",
        subject: "Betreff",
        message: "Beschreiben Sie Ihr Anliegen:",
        country: "Land",
        startup: "Firma",
        comment: "Kommentar",
        phone: "Telefonnummer",
      },
      label: {
        message: "Ihre Nachricht",
      },
      success: "Vielen Dank! Ihre Nachricht wurde erfolgreich versendet.",
      error:
        "Es ist ein Fehler beim Senden Ihrer Nachricht aufgetreten. Versuchen Sie es später erneut oder senden Sie uns eine Nachricht an info@albis.com.",
    },
  },
  contactFinder: {
    title: "Ihre Ansprechpartner",
    description:
      "Damit wir Ihnen bei Ihren Fragen am Besten weitherhelfen können, geben Sir hier ihren Standort und Postleitzahl an. Anschließend wird der passende Ansprechpartner angezeigt.",
    findAction: "Finden",
    zipCodeLabel: "Postleitzahl eingeben",
    countryLabel: "Land wählen",
    contactPersonTitle: "Ihre Ansprechpartnerin / Ihr Ansprechpartner",
    warningMessage:
      "Leider konnten wir keinen Ansprechpartner finden. Bitte überprüfen Sie Ihre Eingabe von Land und Postleitzahl.",
    contactButton: "Bitte um Kontaktaufnahme",
  },
  contactDialog: {
    title: "Bitte um Kontaktaufnahme",
    contactByPhoneActionLabel:
      "Bitte rufen Sie mich unter der folgenden Telefonnummer zurück:",
    dataProtectionConsentActionLabel:
      "Ich stimme den <a href='https://www.albis.com/de/privacy' target='_blank'>Datenschutzbedingungen</a> zu.",
    requestMsgLabel: "Was ist der Grund für Ihre Anfrage?",
    submit: "Nachricht absenden",
    requestMessage: "Hier eingeben",
    successTitle: "Wir melden uns bei Ihnen.",
    successDescription:
      "Ihre Anfrage wurde erfolgreich übermittelt. Wir werden uns schnellstmöglich bei Ihnen melden.",
    errorDescription: "Da ist etwas schief gegangen",
    successBtnLabel: "Zurück zur Seite",
  },
  media: {
    press: {
      categories: {
        products: "Produkte",
        company: "Unternehmen",
        "shows-and-events": "Shows & Events",
        "people-at-albis": "Menschen@ALBIS",
      },
      loadMore: "Mehr Einträge laden",
    },
    events: {
      addToCalendar: "Zum Kalender hinzufügen",
    },
    filter: {
      year: "Erscheinungsjahr",
      topic: "Thema",
    },
    noResults: "Noch keine Einträge",
  },
  markets: {
    cases: "Case Studies",
    productInformation: "Produktinformationen",
    categories: {
      interior: "Innen",
      exterior: "Außen",
      "under-the-hood": "Motorraum",
      outdoor: "Gartengeräte",
      hvac: "Heiz-, Kühl und Lüftungstechnik",
      furniture: "Möbel",
      kitchenware: "Elektrokleingeräte und Haushaltswaren",
      sanitary: "Sanitär",
      tools: "Elektrowerkzeuge",
      wintersports: "Berg- und Wintersport",
      sportswear: "Sportbekleidung",
      marine: "Wassersport",
      materials: "Materiale",
      vehicles: "Fahrzeuge",
      trains: "Züge",
      digger: "Bagger",
      solar: "Erneuerbare Energien",
      radio: "Radio",
      video: "Sicherheitstechnik",
      computer: "Elektrogroßgeräte",
      lights: "Lichttechnik",
      electricity: "Elektroinstallation",
      machine: "Automaten",
      robots: "Roboter",
      aircondition: "Klimaanlagen",
      safety: "Sicherheit",
      monitors: "Monitore",
      controlling: "Kontrolle",
      tubes: "Schläuche",
      bedside: "Bettmaterial",
      packaging: "Verpackungen",
      bicycle: "Radsport",
      household: "Haushaltsgeräte",
      "post-industrial": "Post industrielles Recycling",
      renewable: "Erneuerbare Ressourcen",
      mechanical: "Wertstoffliches Recycling",
      energy: "Energetisches Recycling",
      biobased: "Biobasierte Rohstoffe",
      "post-consumer": "Post-Consumer Recycling",
      diagnostic: "In vitro-Diagnostik, Laborbedarf und Biotechnologie",
      infusion: "Medizinprodukte - Infusionssysteme",
      "drug-delivery": "Medizinprodukte - Medikamentenverabreichung",
      monitoring: "Medizinprodukte- Intensivtherapie und Patienten-Monitoring",
      "medical-packaging":
        "Verpackungen von pharmazeutischen Erzeugnissen und Medizinprodukten",
      "medical-devices":
        "Weitere Medizinprodukte - Anwendungsgebiete Chirurgie, Rehabilitation, Blutspende, Impfung (Beispiele)",
    },
  },
  okg: {
    jobTeaser: {
      student: "Studierende",
      graduate: "Schulabgänger",
      youngprofessional: "Berufseinsteiger",
      professional: "Berufserfahrene",
    },
    pressTeaser: {
      news: "News",
      readMore: "Weiterlesen",
    },
    privacy: "Datenschutzerklärung",
  },
  htf: {
    tempRange: "Temperaturbereich:",
    modeDetails: "Weitere Produktdetails",
    close: "Schließen",
    typicalApplication: "Typische Anwendungsgebiete",
    descriptionOfServices: "Leistungsbeschreibung",
    productData: "Produktdaten",
    searchPlaceholder: "Suche nach Produkt",
    resetFilter: "Filter zurücksetzen",
    filter: "Filter",
    thermometerMinLabel: "Min. Temperatur (°C)",
    thermometerMaxLabel: "Max. Temperatur (°C)",
    thermometerDescription:
      "Verschieben Sie die Regler zum Filtern der Produkte.",
    tempRangeFormat: "#t1# °C bis #t2# °C",
    productInformation: {
      tempRange: "Temperaturbereich",
      optimalUsageTemp: "Optimale Nutzungstemperatur °C",
      maxBulkTemp: "Empfohlene Vorlauftemperatur °C",
      maxExtendedBulkTemp: "Max. Vorlauftemperatur °C",
      maxFilmTemp: "Max. Filmtemperatur °C",
      boilingPoint: "Siedepunkt °C @ 1013 mbar ",
      pumpability: "Pumpbarkeit °C @ 300 mm²/s (cSt)",
      pourPoint: "Stockpunkt °C",
      crystallizingPoint: "Kristallisationspunkt °C",
      slurryPoint: "Schmelztemperatur °C",
      flashPointD93: "Flammpunkt °C (ASTM D93)",
      flashPointD92: "Flammpunkt °C (ASTM D92)",
      autoignitionTempDIN51794: "Selbstentzündungstemperatur °C (DIN 51794)",
      autoignitionTempASTME659: "Selbstentzündungstemperatur °C (ASTM E-659)",
      density20: "Dichte @ 20 °C (kg/m³)",
      density25: "Dichte @ 25 °C (kg/m³)",
      density80: "Dichte @ 80 °C (kg/m³)",
      kinematicViscosity25: "Kinematische Viskosität @ 25 °C (mm²/s)",
      kinematicViscosity40: "Kinematische Viskosität @ 40 °C (mm²/s)",
      kinematicViscosity80: "Kinematische Viskosität @ 80 °C (mm²/s)",
      waterClass: "Wassergefährdungsklasse (WGK)",
      approval: "Approval",
    },
    pTypicalApplication: {
      "product-ta-oil": "Öl- und Gasverarbeitung",
      "product-ta-ng-purification": "Erdgasaufbereitung",
      "product-ta-refining": "Raffinerieprozesse",
      "product-ta-aps": "Asphaltaufbereitung und -lagerung",
      "product-ta-gtl": "Gas-to-Liquid Anwendung (GTL)",
      "product-ta-pp-manufacturing": "Kunststoff- und Polymerherstellung",
      "product-ta-shp-manufacturing": "Herstellung wärmeempfindliche Polymere",
      "product-ta-sfm": "Synthetische Faserherstellung",
      "product-ta-plastic-molding": "Kunststoff Spritzguss",
      "product-ta-pharmaceutical-manufacturing": "Pharmazeutische Verarbeitung",
      "product-ta-chiral-chemical-processes": "Chirale chemische Prozesse",
      "product-ta-environmental-test-chambers": "Klimakammern",
      "product-ta-specialty-chemical-manufacturing":
        "Herstellung von Spezialchemikalien",
      "product-ta-chemical-manufacturing": "Chemische Verfahren",
      "product-ta-concentrated-solar-power": "CSP Solarthermie",
      "product-ta-orc": "Organic-Rankine-Cycle (ORC)",
      "product-ta-biofuel": "Biokraftherstellung",
      "product-ta-foodbeverage-processing":
        "Lebensmittel- und Getränkeherstellung",
      "product-ta-mdf": "Faserplatte (MDF)",
      "product-ta-desalination": "Wasserentsalzung",
      "product-ta-htsm": "Wartung/Instandhaltung des Wärmeträger Systems",
    },
    checkboxHeadline: "Applikation auswählen",
    tempRangeHeadline: "Temperaturbereich °C",
    checkboxFilterLabels: {
      heatingAndRecovery: "Heizen / Wärmerückgewinnung",
      heatingAndCooling: "Temperieren",
      cooling: "Kühlen",
      thermoRegulation: "Thermostat",
      foodgrade: "Lebensmittelindustrie",
    },
    noProductsFound:
      "Es wurden keine Produkte mit Ihren Suchkriterien gefunden.",
    wrongProductId: {
      title: "Produkt nicht gefunden",
      text: "Wir konnten das von Ihnen gesuchte Produkt leider nicht finden.",
      cta: "Zur Übersicht",
    },
  },
  krahnCeramics: {
    openImage: "Bild öffnen",
  },
  krahnChemie: {
    contact: {
      form: {
        placeholder: {
          message: "Geben Sie Ihre Nachricht ein",
        }
      }
    }
  },
  scroll: "Scroll to discover",
  brochure: "Broschüren",
  productInformation: "Produktinformationen",
  innovationChallenge: {
    jobs: {
      student: "Studentin oder Student",
      startUpStaff:
        "Mitarbeiterin oder Mitarbeiter eines interessierten Startups",
      universityStaff:
        "Mitarbeiterin oder Mitarbeiter einer Universität, Hochschule, eines Instituts oder einer vergleichbaren Einrichtung",
    },
  },
  redirect: {
    title: "Ein Fehler ist aufgetreten.",
    text: "Meinten Sie {{}}?",
    buttonLabel: "Zur richtigen Seite",
  },
  pageNotFound: {
    title: "Die Seite konnte nicht gefunden werden(404).",
    text:
      "Die Seite konnte nicht gefunden werden oder beim Aufrufen ist ein Fehler aufgetreten.",
    buttonLabel: "Zurück",
  },
  clientArea: {
    successMessage: "Ihre Anfrage wurde erfolgreich versandt.",
    errorMessage:
      "Etwas ist schief gelaufen. Bitte versuchen Sie es noch mal und überprüfen Sie Ihre Eingaben.",
    titleOrder: "Ihre Auftragsnummer: ",
    titleAlbisNr: "Liefernummer: ",
    materialName: "Materialname",
    materialNumber: "Materialnr.",
    dataSource: "Datenblatt",
    sdb: "SDB",
    apz: "APZ",
    orderNr: "Auftragsnr.",
    orderNrLong: "Auftragsnummer",
    albisNr: "Liefernr.",
    date: "Datum",
    name: "Name",
    documentLabel: "Dokument",
    backToOverview: "Zurück zur Übersicht",
    contactBoxTitle: "Ihr Kontakt",
    contactBoxAction: "Um Rückruf bitten",
    orderInsight: {
      orderNumberLabel: "Ihre Auftragsnummer: ",
      orderConfirmation: "Auftragsbestätigung",
      safetySheetLabel: "Sicherheitsdatenblatt",
      status: "Status ",
      estDeliveryDate: "Geplantes Lieferdatum",
      materialQuantity: "Anzahl Materialien",
      deliveryDocs: "Dokumente zur Bestellung",
      albisDeliveries: "Albis Lieferungen",
    },
    albisOrderInsight: {
      partOfOrder: "Teil der Auftragsnummer:",
      showOrder: "Auftrag anzeigen",
      materialName: "Materialname",
      materialNumber: "Materialnummer",
      amount: "Menge",
      chargeId: "Chargennummer",
      materials: "Materialien",
    },
    productOrderDialog: {
      title: "Produkt nachbestellen",
      description:
        "Wenn Sie dieses Produkt erneut bestellen möchten, füllen Sie das Formular aus und der zuständige Albis-Mitarbeiter wird sich mit Ihnen in Verbindung setzen.",
      placeholder: {
        company: "Firma",
        useCase: "Anwendung (optional)",
        useCase2: "Anwendung",
        technicalRequirements: "Technische Vorraussetzung (optional)",
        technicalRequirements2: "Technische Vorraussetzung",
        volume: "Volumen",
        enterHere: "Hier eingeben",
        color: "Farbe",
        product: "Produkt",
      },
      phoneRecallLabel:
        "Bitte rufen Sie mich unter der angegebenen Telefonnummer zurück.",
      moreProductsLabel: "Ich habe Interesse an weiteren Produkten.",
      productNameLabel: "Ausgewähltes Product: ",
      notesLabel: "Weitere Notizen",
      fileUploadLabel: "Laden Sie ein Dokument hoch",
      submitBtnLabel: "Anfrage absenden",
    },
    productRequestDialog: {
      title: "Was für ein Produkt suchen Sie?",
      description:
        "Sie sind sich nicht sicher, welches Material das richtige für Ihre Anwendung ist? Füllen Sie das folgende Formular aus und lassen Sie sich von unseren Experten bei Ihrer Entscheidung helfen.",
    },
    filter: {
      timeRange: "Lieferzeitraum wählen",
      refresh: "Aktualisieren",
    },
    sdbHint: "SDB steht für Sicherheitsdatenblatt.",
    apzHint: "APZ steht für das Abnahmeprüfzeugnis des Produkts.",
    login: {
      title: "Login",
      form: {
        mail: "E-Mail",
        customerNr: "Kundennummer",
        orderNr: "Auftragsnummer (optional)",
        hint:
          "Durch zusätzliche Eingabe Ihrer Auftragsnummer gelangen Sie direkt zum Auftrag.",
      },
      stayLoggedIn: "Angemeldet bleiben",
      modal: {
        headline: "Sie haben eine E-Mail erhalten.",
        text:
          "Wir haben Ihnen einen Magic Link an Ihre E-Mail Adresse geschickt. Wenn Sie den Link öffnen gelangen Sie direkt zu der Übersichtseite Ihrer bisherigen Bestellungen.",
      },
    },
    moreOrders: "Weitere offene Bestellungen",
    openOrder: "Bestellung öffnen",
    error: {
      title: "Es ist ein Fehler aufgetreten.",
      albisIdText:
        "Unter dieser Liefernummer konnten wir gerade nichts finden. Bitte überprüfen Sie Ihre Eingabe.",
      orderIdText:
        "Unter dieser Ordernummer konnten wir gerade nichts finden. Bitte überprüfen Sie Ihre Eingabe.",
    },
    upload: {
      title: "Laden Sie ein Dokument hoch",
      success: "wurde erfolgreich hochgeladen.",
      hint: "Datei löschen",
      error:
        "Der Upload war nicht erfolgreich. Bitte versuchen Sie es nochmal.",
    },
    startCall: {
      title: "Gespräch starten...",
      text:
        "Wenn Sie an weiteren Informationen über das von Ihnen gewählte Produkt interessiert sind, füllen Sie das Formular aus und unsere Experten nehmen zeitnahe Kontakt mit Ihnen auf.",
      productAdornment: "Produkt: ",
    },
    downloadsHint: "Der Dokumentendownload ist bald verfügbar.",
    orderDownloadsHint: {
      label: "Der Dokumentendownload ist momentan nicht verfügbar. ",
      link: " Alternativ kontaktieren Sie uns bitte.",
    },
    states: {
      ordered: "Bestellt",
      confirmed: "Bestätigt",
      scheduled: "Geplant",
      shipped: "Versendet",
      delivered: "Geliefert",
      unknown: "Unbekannt",
      inProgress: "In Bearbeitung",
    },
  },
  recyclingForm: {
    polymer: "Polymerart",
    mold: "Form",
    filler: "Füllstoff",
    color: "Farbe",
    amount: "Menge",
    description: "Beschreibung",
    adhesion: "Sonstige Anhaftungen",
    moreInfo: "Zusatzinfos",
    personalInfo: "Ihre Angaben",
    name: "Name, Nachname",
    address: "Anschrift",
    email: "E-Mail",
    phone: "Telefon",
    message: "Geben Sie hier eine Nachricht ein.",
    submit: "Formular absenden",
    save: "Speichern",
    delete: "Löschen",
    add: "Weitere Abfälle hinzufügen",
    success: "Vielen Dank! Ihre Nachricht wurde erfolgreich versendet.",
    error:
      "Es ist ein Fehler beim Senden Ihrer Nachricht aufgetreten. Versuchen Sie es später erneut oder senden Sie uns eine Nachricht an info@wipag.com.",
    notSaved: "Noch nicht gespeichert",
  },
  floatingButtonWipag: "Kunststoff-Ankauf",
  regions: {
    de: "Deutschland",
    at: "Österreich",
    ch: "Schweiz",
    uk: "Vereinigtes Königreich",
    ie: "Irland",
    pl: "Polen",
    ro: "Rumänien",
    cz: "Tschechische Republik",
    hu: "Ungarn",
    sk: "Slowakei",
    be: "Belgien",
    nl: "Niederlande",
    lu: "Luxemburg",
    it: "Italien",
    fr: "Frankreich",
    es: "Spanien",
    cn: "China",
    gr: "Griechenland",
    il: "Israel",
    ru: "Russland",
    se: "Schweden",
    no: "Norwegen",
    fi: "Finnland",
    dk: "Dänemark",
    is: "Island",
    bg: "Bulgarien",
    lv: "Lettland",
    ee: "Estland",
    lt: "Litauen",
    ba: "Bosnien und Herzegowina",
    hr: "Kroatien",
    me: "Montenegro",
    mk: "Mazedonien",
    si: "Slowenien",
    rs: "Serbien",
    cy: "Zypern",
    li: "Liechtenstein",
    pt: "Portugal",
    xk: "Kosovo",
    ua: "Ukraine",
  },
  productArea: {
    selectRegion: "Sehen Sie, was in Ihrer Region verfügbar ist",
    filterApplication: "Filtern nach Anwendungen",
    filterSolution: "Filtern nach Lösungen",
    filterIdea: "Filtern nach Ideen",
    filterManufacturer: "Filtern nach Hersteller",
    filterLubricants: "Filtern nach Schmierstoffen",
    filterFuels: "Filtern nach Kraftstoffen",
    industry: "Industrie auswählen",
    contact: "Kontakt",
    contactButton: "Kontaktdetails",
    back: "Zurück zur Übersicht",
    characteristics: "Produkteigenschaften",
    brands: "Marken",
    producer: "Produzenten",
    application: "Anwendungen",
    ideas: "Ideen",
    lubricants: "Lubricants",
    fuels: "Fuels",
    manufacturer: "Hersteller",
    availability: "Verfügbar in",
    search: "Nach Produktnamen suchen",
    searchResults: "Suchergebnisse für:",
    showAllProducts: "Alle {{}} Produktgruppen anzeigen",
    noResults: "Keine Ergebnisse gefunden",
    noContentMaintained:
      "Für diese Industrie konnten wir leider keine Inhalte finden. Schauen Sie später noch mal vorbei!",
    resetFilter: "Filter zurücksetzen",
    allCountries: "Wählen Sie ein Land aus",
  },
  jobpages: {
    applyNow: "Jetzt bewerben!",
    bePartOfTeam: "Werde Teil unseres Teams als:",
    tasks: {
      title: "Deine Aufgaben",
      main: "Hauptaufgaben",
      additional: "Weitere Aufgaben",
    },
    profile: {
      title: "Dein Profil",
      skills: "Erfahrungen und Know-how",
      personality: "Persönlichkeit",
    },
    offers: "Angebote im Stellenmarkt",
    showOffers: "{{}} Stellenangebote anzeigen",
    selection: "Deine Auswahl",
    filterArea: {
      freeText: "Stichwort",
      freeTextPlaceholder: "Stichwort, Tätigkeit, Job-ID",
      department: "Tätigkeitsbereich",
      fallbackDepartment: "Alle Tätigkeitsbereiche",
      location: "Standort",
      fallbackLocation: "Alle Standorte",
      level: "Einstiegslevel",
      fallbackLevel: "Alle Einstiegslevel",
      company: "Gesellschaft",
      fallbackCompany: "Alle Gesellschaften",
    },
    contract: {
      limited: "befristet",
      unlimited: "unbefristet",
    },
    level: {
      starter: "Berufseinsteiger",
      professional: "Berufserfahrene",
      students: "Studierende",
      graduate: "Schulabgänger",
    },
    pagesPerPage: "Stellen pro Seite",
    error: {
      jobDetail:
        "Es ist ein Fehler beim Laden der Stellenbeschreibung aufgetreten.",
      jobboard:
        "Es ist ein Fehler beim Laden der Stellenangebote aufgetreten. Bitte versuche es später erneut.",
    },
    sortingLabel: "Sortieren nach",
    sortingOptions: {
      companyAsc: "Unternehmen aufsteigend",
      companyDesc: "Unternehmen absteigend",
      titleAsc: "Stellentitel aufsteigend",
      titleDesc: "Stellentitel absteigend",
      startOfWorkAsc: "Startdatum aufsteigend",
      startOfWorkDesc: "Startdatum absteigend",
      locationAsc: "Standort aufsteigend",
      locationDesc: "Standort absteigend",
    },
    departments: {
      finance: "Finanzen, Rechnungswesen und Controlling",
      customerService: "Customer Service und Kundenbetreuung",
      graphics: "Grafik, Design und Architektur",
      distribution: "Vertrieb und Handel",
      management: "Management und Unternehmensentwicklung",
      it: "IT und Softwareentwicklung",
      law: "Recht",
      purchase: "Einkauf, Materialwirtschaft und Logistik",
      marketing: "Marketing und Werbung",
      others: "Sonstige Tätigkeitsfelder",
      projectManagement: "Projektmanagement",
      production: "Produktion und Handwerk",
      pr: "PR, Öffentlichkeitsarbeit und Journalismus",
      processPlanning: "Prozessplanung und Qualitätssicherung",
      teaching: "Forschung, Lehre und Entwicklung",
      health: "Gesundheit, Medizin und Soziales",
      consulting: "Beratung / Consulting",
      administration: "Administration, Sachbearbeitung und Verwaltung",
      engineering: "Ingenieurwesen und technische Berufe",
      hr: "Personalwesen und HR",
      productManagement: "Produktmanagement",
      analysis: "Analyse und Statistik",
    },
    errorDetails: {
      title: "Job konnte nicht gefunden werden",
      text:
        "Dieser Job existiert leider nicht mehr. Bitte wähle einen anderen Job aus.",
      buttonLabel: "Zurück",
    },
    errorJobboard: {
      title: "Ein Fehler ist aufgetreten",
      text:
        "Das Laden der Stellenangebote ist fehlgeschlagen. Bitte versuche es zu einem späteren Zeitpunkt erneut.",
      buttonLabel: "Erneut versuchen",
    },
    noJobsFound: {
      title: "Deine Suche ergab leider keine Treffer.",
      text: "Verändere die Kriterien und probiere es gerne erneut.",
    },
    position: {
      fulltime: "Vollzeit",
      parttime: "Teilzeit",
      intern: "Praktikum",
      freelance: "Freelancer",
      owner: "Eigentümer",
      partner: "Partner",
      board: "Aufsichtsratmitglied",
      honorary: "Ehrenamt",
      partFull: "Teilzeit / Vollzeit",
      trainee: "Traineeship",
      help: "Aushilfe",
      social: "Soziales Engagement",
      apprentice: "Lehre",
      training: "Ausbildung",
      marginal: "Geringfügig",
      student: "Werkstudent",
      temp: "Befristet / Saisonal",
      dual: "Duales Studium",
    },
  },
  a11y: {
    showContent: "Klicken um Inhalt für {{}} anzuzeigen",
  },
};
